import { useHover } from '@/helpers/hooks/useHover'
import styles from './HeaderNavigation.module.scss'
import cx from 'classnames'
import { ReactElement, useMemo } from 'react'
import MegaMenu from '@/components/header/header-navigation/MegaMenu'
import { HeaderDropDown } from '@/components/header/header-navigation/HeaderDropDown'
const megaMenuItems = ['guides', 'liveaboards', 'diveResorts']

export const getRegionIdentifier = (region: string) => {
  switch (region) {
    case 'adventures':
      return 'menu-travelmain-diving'
    case 'courses':
      return 'menu-travelmain-courses'
    case 'guides':
      return 'menu-travelmain-diveguides'
    case 'liveaboards':
      return 'menu-travelmain-vacations-liveaboards'
    case 'diveResorts':
      return 'menu-travelmain-vacations-diveresorts'
    case 'deals':
      return 'menu-travelmain-deals'
  }
}

export interface INavigationItem {
  title: ReactElement | string
  url: string
  subItems?: INavigationItem[]
}

interface IHeaderNavigationItem {
  navigationItem: INavigationItem
  code?: string
}

export default function HeaderNavigationSection({ navigationItem, code }: IHeaderNavigationItem) {
  const { isActive, handleBlur, handleFocus } = useHover()
  const isMegaMenu = useMemo(() => megaMenuItems.includes(code), [code])
  return (<div onMouseOver={handleFocus} onMouseLeave={handleBlur} className={cx({ [styles.smallMenu]: !isMegaMenu })}>
    {
      navigationItem.url ? <a href={navigationItem.url} className={styles.headerLink}>{navigationItem.title}</a> :
        <span className={styles.headerLink}>{navigationItem.title}</span>
    }
    {isMegaMenu ? <MegaMenu navigationItem={navigationItem} isActive={isActive} code={code} /> :
      <HeaderDropDown navigationItem={navigationItem} code={code} isActive={isActive} />}
  </div>)
}

