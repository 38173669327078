import { Fragment } from 'react'
import moment from 'moment'
import { useRouter } from 'next/dist/client/router'

type DateFormatProps = {
  date: string
  format?: string
}

export default function DateFormat({ date, format = 'll' }: DateFormatProps) {
  const { locale } = useRouter()
  const IS_UTC = date && typeof date === 'string' ? date.indexOf('T') !== -1 : true

  return (
    <Fragment>
      {
        date ? (IS_UTC ? moment(date).locale(locale).utc().format(format) : moment(date).locale(locale).format(format)) : ''
      }
    </Fragment>
  )
}
