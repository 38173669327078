import { IMainDestinations } from '../../../interfaces'
import { Fragment, ReactElement } from 'react'
import cx from 'classnames'
import styles from '@/components/header/header-navigation/HeaderNavigation.module.scss'
import { createUrl } from '@/helpers/routes'
import { FormattedMessage } from 'react-intl'
import sortBy from 'lodash/sortBy'
import { SubItem } from '../../../interfaces/MenuItems'
import { getRegionIdentifier, INavigationItem } from '@/components/header/header-navigation/HeaderNavigationSection'

interface IMegaMenu {
  navigationItem: INavigationItem | IMainDestinations
  isActive: boolean
  code?: string
  isStatic?: boolean
  id?: string
  hint?: ReactElement | string
}

export default function MegaMenu({ navigationItem, isActive, code, isStatic, id, hint }: IMegaMenu) {
  return (<div className={cx(styles.menu, {
    [styles.activeMenu]: isActive,
    [styles.staticMenu]: isStatic,
  }, 'location-menu-item')} id={id}
  >
    {code === 'guides' && (<a className={cx('btn-red', styles.themeBtn)} href={createUrl('collections')}>
      <FormattedMessage id='travel_theme' defaultMessage='Travel theme' />
    </a>)}
    {hint && <p className={styles.hint}>{hint}</p>}
    <div className={cx(styles.listWrapper, 'location-items-list')}
      style={{ columnCount: navigationItem.subItems.length <= 8 ? 3 : 5 }}
    >
      {
        navigationItem.subItems && navigationItem.subItems.map((item, index, arr) => {
          const isLast = arr.length === index + 1
          const sortedList = sortBy(item.subItems, 'title')
          return (<ul className={cx(styles.list, styles.firstLevelItems)} key={item.title}>
            <li className={styles.listItem}>
              <a className={isLast && !isStatic ? cx('btn-red-invert', styles.btn) : cx(styles.title, styles.link)}
                href={item.url}
              >{item.title}</a>
              {subItemsRender(sortedList, code)}
            </li>
          </ul>)
        })
      }
    </div>
  </div>)
}

const subItemsRender = (subItems: SubItem[], code: string) => {
  return subItems.map((item, index) => {
    const sortedList = sortBy(item.subItems, 'title')

    return (<ul className={cx(styles.list, styles.innerLevel)} key={index}>
      {sortedList?.length > 0
        ? (<Fragment>
          <Item item={item} code={code} key={index} />
          {subItemsRender(sortedList, code)}
        </Fragment>)
        : (<Item item={item} code={code} key={index} />)
      }
    </ul>)
  })
}

const Item = ({ item, code }: { item: SubItem; code: string }) => (
  <li className={styles.listItem} key={item.url || item.title}>
    <a className={styles.link}
      href={item.url}
      data-navigation-elements='data-navigation-elements'
      data-region-identifier={getRegionIdentifier(code)}
    >{item.title}</a>
  </li>
)
