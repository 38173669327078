import { useState, useRef, useMemo, useEffect } from 'react'
import { isMobile } from '../utils'

const delay = isMobile() ? 0 : 300

export function useHover() {
  const timer = useRef<ReturnType<typeof setInterval> | null>()
  const leaveTimer = useRef<ReturnType<typeof setInterval> | null>()
  const [isActive, setIsActive] = useState(false)

  const handleFocus = ():void => {
    clearTimeout(leaveTimer.current)
    timer.current = setTimeout(function() {
      setIsActive(true)
    }, delay)
  }

  const handleBlur = ():void => {
    clearTimeout(timer.current)
    leaveTimer.current = setTimeout(function() {
      setIsActive(false)
    }, delay)
  }

  useEffect(() => {
    return () => {
      clearTimeout(timer.current)
      clearTimeout(leaveTimer.current)
    }
  }, [])

  return useMemo(() => ({
    handleFocus,
    handleBlur,
    isActive,
    setIsActive,
  }), [handleFocus, handleBlur, isActive, setIsActive])
}
