import each from 'lodash/each'
import { useRouter } from 'next-translate-routes/router'
import { getCookie } from './utils'

export const routes = {
  en: {
    homepage: '/',
    adventures: '/adventures/',
    login: '/login/',
    search_la: '/s/liveaboards/:slug/',
    fleets: '/fleets/:slug/',
    search_dr: '/s/dive-resorts/:slug/',
    search_dc: '/s/dive-centers/:slug/',
    search_diving: '/s/diving/:slug/',
    search_courses: '/s/dive-courses/:slug/',
    search_snorkeling: '/s/snorkeling/:slug/',
    la_page: '/liveaboard/:location/:slug/',
    dr_page: '/dive-resort/:location/:slug/',
    dc_page: '/dive-center/:location/:slug/',
    destination: '/d/:location/',
    destinations: '/exploration/',
    checkout_la: '/checkout/liveaboard/:location/:slug/',
    checkout_dr: '/checkout/dive-resort/:location/:slug/',
    widget_search_la: '/widget/s/:slug/',
    widget_search_dr: '/widget/s/dr/:slug/',
    group_trips: '/s/group-trips/:slug/',
    profile: '/profile/',
    dive_buddies: '/dive-buddies/',
    settings: '/settings/',
    preferences: '/preferences/',
    payment_and_coupons: '/payment-and-coupons/',
    bookings: '/bookings/',
    booking_info: '/bookings/:id/',
    wishlist: '/wishlist/',
    recently_viewed: '/recently-viewed/',
    register_completed: '/register/completed/',
    terms: '/terms/',
    checkout_adventures: '/checkout/adventure/:activityId/:date/',
    // dive-guides urls
    diving_in: '/diving-in/:slug/',
    dive_sites: '/dive-sites/:slug/',
    dive_sites_world: '/dive-sites/world/',
    dive_site: '/dive-site/:location/:slug/',
    gis: '/bookings/:id/:uuid/',
    collections: '/collections/',
    logout: '/logout/',
    la_deals: '/liveaboard-deals/',
    dr_deals: '/dive-resort-deals/',
    last_minute_deals: '/last-minute-deals/',
    group_bookings: '/group-bookings/',
    liveaboard_diving_location: '/liveaboard-diving/:location/',
    why_book_with_us: '/why-book-with-us/',
    faq: '/faq/',
    best_price_guarantee: '/best-price-guarantee/',
    liveaboard_diving: '/liveaboard-diving/',
    marketplace_search: '/s/group-trips/:slug/',
    group_trip_page: '/group-trip/:location/:slug/',
    collection: '/c/:slug/',
  },
  fr: {
    homepage: '/fr/',
    login: '/fr/login/',
    adventures: '/fr/adventures/',
    search_la: '/fr/s/croisieres-plongee/:slug/',
    fleets: '/fr/flottes/:slug/',
    search_dr: '/fr/s/resorts-plongee/:slug/',
    search_dc: '/fr/s/centres-plongee/:slug/',
    search_diving: '/fr/s/plongee/:slug/',
    search_courses: '/fr/s/cours-plongee/:slug/',
    search_snorkeling: '/fr/s/snorkeling/:slug/',
    la_page: '/fr/croisiere-plongee/:location/:slug/',
    dr_page: '/fr/resort-plongee/:location/:slug/',
    dc_page: '/fr/centre-plongee/:location/:slug/',
    destination: '/fr/d/:location/',
    destinations: '/fr/exploration/',
    checkout_la: '/fr/checkout/croisiere-plongee/:location/:slug/',
    checkout_dr: '/fr/checkout/resort-plongee/:location/:slug/',
    widget_search_la: '/fr/widget/s/:slug/',
    widget_search_dr: '/fr/widget/s/dr/:slug/',
    group_trips: '/fr/s/voyages-en-groupe/:slug/',
    profile: '/fr/profil/',
    dive_buddies: '/fr/dive-buddies/',
    settings: '/fr/parametres/',
    preferences: '/fr/preferences/',
    payment_and_coupons: '/fr/paiement-et-codes-reduction/',
    bookings: '/fr/reservations/',
    booking_info: '/fr/reservations/:id/',
    wishlist: '/fr/wishlist/',
    recently_viewed: '/fr/consultes-recemment/',
    register_completed: '/fr/inscription/termine/',
    terms: '/fr/conditions/',
    checkout_adventures: '/fr/consulter/aventure/:activityId/:date/',
    // dive-guides urls
    diving_in: '/fr/plonger-en/:slug/',
    dive_sites: '/fr/sites-plongee/:slug/',
    dive_sites_world: '/fr/sites-plongee/monde/',
    dive_site: '/fr/site-plongee/:location/:slug/',
    gis: '/fr/reservations/:id/:uuid/',
    collections: '/fr/thematiques/',
    logout: '/fr/logout/',
    la_deals: '/fr/croisiere-plongee-offres/',
    dr_deals: '/fr/sejour-plongee-offres/',
    last_minute_deals: '/fr/offres-dernières-minutes/',
    group_bookings: '/fr/reservations-groupe/',
    liveaboard_diving_location: '/fr/croisiere-plonger/:location/',
    why_book_with_us: '/fr/pourquoi-reserver-avec-nous/',
    faq: '/fr/faq/',
    // terms_dc: '/fr/conditions/centre-plongee/',
    // imprint: '/fr/impressum/',
    // privacy: '/fr/confidentialite/',
    best_price_guarantee: '/fr/garantie-meilleur-prix/',
    liveaboard_diving: '/fr/croisiere-plonger/',
    marketplace_search: '/fr/s/voyages-en-groupe/:slug/',
    group_trip_page: '/fr/voyage-en-groupe/:location/:slug/',
    collection: '/fr/c/:slug/',
  },
  es: {
    homepage: '/es/',
    login: '/es/iniciar-sesion/',
    adventures: '/es/adventures/',
    search_la: '/es/s/cruceros-de-buceo/:slug/',
    fleets: '/es/flotas/:slug/',
    search_dr: '/es/s/resorts-de-buceo/:slug/',
    search_dc: '/es/s/centros-buceo/:slug/',
    search_diving: '/es/s/buceo/:slug/',
    search_courses: '/es/s/cursos-buceo/:slug/',
    search_snorkeling: '/es/s/esnorquel/:slug/',
    la_page: '/es/crucero-de-buceo/:location/:slug/',
    dr_page: '/es/resort-de-buceo/:location/:slug/',
    dc_page: '/es/centro-buceo/:location/:slug/',
    destination: '/es/d/:location/',
    destinations: '/es/exploracion/',
    checkout_la: '/es/proceso-de-compra/crucero-de-buceo/:location/:slug/',
    checkout_dr: '/es/proceso-de-compra/resort-de-buceo/:location/:slug/',
    widget_search_la: '/es/widget/s/:slug/',
    widget_search_dr: '/es/widget/s/dr/:slug/',
    group_trips: '/es/s/viajes-en-grupo/:slug/',
    profile: '/es/perfil/',
    dive_buddies: '/es/dive-buddies/',
    settings: '/es/ajustes/',
    preferences: '/es/preferencias/',
    payment_and_coupons: '/es/pago-y-cupones/',
    bookings: '/es/reservas/',
    booking_info: '/es/reservas/:id/',
    wishlist: '/es/lista-de-favoritos/',
    recently_viewed: '/es/recien-visitados/',
    register_completed: '/es/registro/completado/',
    terms: '/es/términos/',
    checkout_adventures: '/es/finalizar-compra/aventura/:activityId/:date/',
    // dive-guides urls
    diving_in: '/es/diving-in/:slug/',
    dive_sites: '/es/sitios-buceo/:slug/',
    dive_sites_world: '/es/sitios-buceo/mundo/',
    dive_site: '/es/sitio-buceo/:location/:slug/',
    gis: '/es/reservas/:id/:uuid/',
    collections: '/es/seleccion/',
    logout: '/es/logout/',
    la_deals: '/es/ofertas-crucero-de-buceo/',
    dr_deals: '/es/ofertas-resort-de-buceo/',
    last_minute_deals: '/es/ofertas-ultima-hora/',
    group_bookings: '/es/reservas-grupo/',
    liveaboard_diving_location: '/es/buceo-crucero-de-buceo/:location/',
    why_book_with_us: '/es/por-que-reservar-con-nosotros/',
    faq: '/es/faq/',
    best_price_guarantee: '/es/mejor-precio-garantizado/',
    liveaboard_diving: '/es/buceo-crucero-de-buceo/',
    marketplace_search: '/es/s/viajes-en-grupo/:slug/',
    group_trip_page: '/es/viaje-en-grupo/:location/:slug/',
    collection: '/es/c/:slug/',
  },
  de: {
    homepage: '/de/',
    login: '/de/login/',
    adventures: '/de/adventures/',
    search_la: '/de/s/tauchsafaris/:slug/',
    fleets: '/de/flotten/:slug/',
    search_dr: '/de/s/tauchresorts/:slug/',
    search_dc: '/de/s/tauchcenter/:slug/',
    search_diving: '/de/s/tauchen/:slug/',
    search_courses: '/de/s/tauchkurse/:slug/',
    search_snorkeling: '/de/s/schnorcheln/:slug/',
    la_page: '/de/tauchsafari/:location/:slug/',
    dr_page: '/de/tauchresort/:location/:slug/',
    dc_page: '/de/tauchcenter/:location/:slug/',
    destination: '/de/d/:location/',
    destinations: '/de/entdecken/',
    checkout_la: '/de/kasse/tauchsafari/:location/:slug/',
    checkout_dr: '/de/kasse/tauchresort/:location/:slug/',
    widget_search_la: '/de/widget/s/:slug/',
    widget_search_dr: '/de/widget/s/dr/:slug/',
    group_trips: '/de/s/gruppenreisen/:slug/',
    profile: '/de/profil/',
    dive_buddies: '/de/dive-buddies/',
    settings: '/de/einstellungen/',
    preferences: '/de/praeferenzen/',
    payment_and_coupons: '/de/zahlung-und-coupons/',
    bookings: '/de/buchungen/',
    booking_info: '/de/buchungen/:id/',
    wishlist: '/de/wunschzettel/',
    recently_viewed: '/de/vor-kurzem-angesehen/',
    register_completed: '/de/registrieren/abgeschlossen/',
    terms: '/de/teilnahmebedingungen/',
    checkout_adventures: '/de/zahlung/adventure/:activityId/:date/',
    // dive-guides urls
    diving_in: '/de/tauchen-in/:slug/',
    dive_sites: '/de/tauchplaetze/:slug/',
    dive_sites_world: '/de/tauchplaetze/weltweit/',
    dive_site: '/de/tauchplatz/:location/:slug/',
    collections: '/de/reisethemen/',
    gis: '/de/buchungen/:id/:uuid/',
    logout: '/de/logout/',
    la_deals: '/de/tauchsafari-angebote/',
    dr_deals: '/de/tauchresort-angebote/',
    last_minute_deals: '/de/last-minute-angebote/',
    group_bookings: '/de/gruppenbuchungen/',
    liveaboard_diving_location: '/de/tauchsafari-tauchen/:location/',
    why_book_with_us: '/de/warum-bei-uns-buchen/',
    faq: '/de/faq/',
    best_price_guarantee: '/de/bestpreisgarantie/',
    liveaboard_diving: '/de/tauchsafari-tauchen/',
    marketplace_search: '/de/s/gruppenreisen/:slug/',
    group_trip_page: '/de/gruppenreise/:location/:slug/',
    collection: '/de/c/:slug/',
  },
  it: {
    homepage: '/it/',
    login: '/it/login/',
    adventures: '/it/adventures/',
    search_la: '/it/s/crociere-sub/:slug/',
    fleets: '/it/flotte/:slug/',
    search_dr: '/it/s/viaggi-sub/:slug/',
    search_dc: '/it/s/centri-sub/:slug/',
    search_diving: '/it/s/immersioni/:slug/',
    search_courses: '/it/s/corsi-sub/:slug/',
    search_snorkeling: '/it/s/snorkeling/:slug/',
    la_page: '/it/crociera-sub/:location/:slug/',
    dr_page: '/it/viaggio-sub/:location/:slug/',
    dc_page: '/it/centro-sub/:location/:slug/',
    destination: '/it/d/:location/',
    destinations: '/it/esplorazione/',
    checkout_la: '/it/checkout/crociera-sub/:location/:slug/',
    checkout_dr: '/it/checkout/viaggio-sub/:location/:slug/',
    widget_search_la: '/it/widget/s/:slug/',
    widget_search_dr: '/it/widget/s/dr/:slug/',
    group_trips: '/it/s/viaggi-di-gruppo/:slug/',
    profile: '/it/profilo/',
    dive_buddies: '/it/dive-buddies/',
    settings: '/it/impostazioni/',
    preferences: '/it/preferenze/',
    payment_and_coupons: '/it/pagamento-e-coupon/',
    bookings: '/it/prenotazioni/',
    booking_info: '/it/prenotazioni/:id/',
    wishlist: '/it/lista-dei-desideri/',
    recently_viewed: '/it/visti-recentemente/',
    register_completed: '/it/registra/completato/',
    terms: '/it/termini/',
    checkout_adventures: '/it/checkout/avventura/:activityId/:date/',
    // dive-guides urls
    diving_in: '/it/immersioni-in/:slug/',
    dive_sites: '/it/siti-immersione/:slug/',
    dive_sites_world: '/it/siti-immersione/mondo/',
    dive_site: '/it/sito-immersione/:location/:slug/',
    gis: '/it/prenotazioni/:id/:uuid/',
    collections: '/it/collezioni/',
    logout: '/it/logout/',
    la_deals: '/it/offerte-crociere-sub/',
    dr_deals: '/it/offerte-viaggi-sub/',
    last_minute_deals: '/it/offerte-last-minute/',
    group_bookings: '/it/prenotazioni-gruppi/',
    liveaboard_diving_location: '/it/vacanze-crociere-sub/:location/',
    why_book_with_us: '/it/perché-prenotare-con-noi/',
    faq: '/it/faq/',
    terms_dc: '/it/termini/dive-center/',
    imprint: '/it/imprint/',
    privacy: '/it/privacy/',
    best_price_guarantee: '/it/garanzia-miglior-prezzo/',
    liveaboard_diving: '/it/vacanze-crociere-sub/',
    marketplace_search: '/it/s/viaggi-di-gruppo/:slug/',
    group_trip_page: '/it/viaggio-di-gruppo/:location/:slug/',
    collection: '/it/c/:slug/',
  },
}

const ALL_SLUG_MAPPING = {
  en: 'all',
  de: 'alle',
  fr: 'tout',
  it: 'tutto',
  es: 'todo',
}

export function createFullUrl(path, language) {
  let lang = language

  if(!routes[lang]) {
    lang = 'en'
  }

  return routes[lang][path]
}

export function createPadiUrl(path: string, params?: Record<string, unknown>, query?: string): string {
  let locale
  try {
    locale = useRouter().locale
  } catch (e) {
    locale = getCookie('travel_language')
  }
  let url = 'https://padi.com' + createFullUrl(path, locale)
  if(params) {
    each(params, function(val, key) {
      url = url.replace(':' + key, val === 'all' && ALL_SLUG_MAPPING[locale] ? ALL_SLUG_MAPPING[locale] : val)
    })
  }

  if(query) {
    url += query
  }

  return url
}

export function createUrl(path: string, params?: Record<string, unknown>, query?: string): string {
  let locale
  try {
    locale = useRouter().locale
  } catch (e) {
    locale = getCookie('travel_language')
  }
  let url = createFullUrl(path, locale)
  if(params) {
    each(params, function(val, key) {
      url = url.replace(':' + key, val === 'all' && ALL_SLUG_MAPPING[locale] ? ALL_SLUG_MAPPING[locale] : val)
    })
  }

  if(query) {
    url += query
  }

  return url
}
