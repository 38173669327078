import { convertCurrency } from './PriceConverter'

function trackEcommerce(event: object, ecommerceData: object) {
  if(!window.dataLayer) {
    return false
  }

  const data:Record<string, object> = { ecommerce: ecommerceData }

  if(event) {
    data.event = event
  }

  return window.dataLayer.push(data)
}

function pushToDataLayer(data, event?: Record<string, any>) {
  if(!window.dataLayer) {
    return false
  }

  if(event) {
    data.event = event
  }

  return window.dataLayer.push(data)
}

function calculateFloodlight(
  rate,
  shopCurrency,
  selectedCurrency,
  totalGuests,
) {
  if(rate.price) {
    // fix for LA;
    rate.compareRatePrice = rate.compareAtPrice
    rate.ratePrice = rate.price
  }
  const GTMFloodLight = {
    page_price: Math.round(
      convertCurrency(rate.ratePrice, shopCurrency, selectedCurrency) /
        totalGuests,
    ),
    page_price_sale: undefined,
    page_price_percent: undefined,
    page_price_currency: selectedCurrency,
  }
  if(rate.compareRatePrice > rate.ratePrice) {
    GTMFloodLight.page_price = Math.round(
      convertCurrency(rate.compareRatePrice, shopCurrency, selectedCurrency) /
        totalGuests,
    )
    GTMFloodLight.page_price_sale = Math.round(
      convertCurrency(rate.ratePrice, shopCurrency, selectedCurrency) /
        totalGuests,
    )
    GTMFloodLight.page_price_percent =
      (1 - (rate.compareRatePrice - rate.ratePrice) / rate.compareRatePrice) *
      100
  }
  return GTMFloodLight
}

function setBrandValue(diveCenter) {
  return `${diveCenter.title}|${
    diveCenter.affiliateId
      ? diveCenter.affiliateId
      : diveCenter.id + '-Individual'
  }`
}

const trackClick = ({
  regionIdentifier,
  link,
  eventCategory = 'Navigation',
  eventAction = 'Click',
  customLabel,
  englishUrl,
}: {
  regionIdentifier: string
  link: string
  eventCategory?: string
  eventAction?: string
  customLabel?: string
  englishUrl?: string
}) => {
  const href = linkTransform(link)

  pushToDataLayer({
    event: 'interaction',
    eventCategory,
    eventAction,
    eventLabel:
      customLabel ||
      `${removeQueryParams(englishUrl)}_${regionIdentifier}_${href}`,
    eventValue: 0,
    eventNonInteraction: false,
  })
}

function removeQueryParams(url) {
  if(!url) {
    return '/'
  }
  return url.split('?')[0]
}
function linkTransform(link) {
  let url = '##'

  if(link) {
    if(link.indexOf('://') === -1 && link[0] === '/') {
      url = window.location.origin + link
    } else {
      url = link
    }

    url = url.replace('https://', '').replace('http://', '')
  }

  return removeQueryParams(url) // link should be without query parameters
}

const navigationClicks = (englishUrl) => {
  onClick('[data-navigation-elements]', (element) => {
    const { regionIdentifier, fakeUrl } = element.dataset
    const link = element.href || fakeUrl
    const href = linkTransform(link)

    pushToDataLayer({
      event: 'interaction',
      eventCategory: 'Navigation',
      eventAction: 'Click',
      eventLabel: `${removeQueryParams(englishUrl)}_${regionIdentifier}_${href}`,
      eventValue: 0,
      eventNonInteraction: false,
    })
  })
}

export function onClick(selector, callback) {
  document.addEventListener('click', function(event) {
    const selectors = document.querySelectorAll(selector)
    let element = event.target as HTMLElement
    let index = -1
    if(selectors) {
      while(
        element &&
        (index = Array.prototype.indexOf.call(selectors, element)) === -1
      ) {
        element = element.parentElement
      }

      if(index > -1) {
        callback.call(this, element, event)
      }
    }
  })
}

export { trackEcommerce, pushToDataLayer, calculateFloodlight, setBrandValue, trackClick, navigationClicks }
