import cx from 'classnames'
import styles from '@/components/header/header-navigation/HeaderNavigation.module.scss'
import { getRegionIdentifier, INavigationItem } from '@/components/header/header-navigation/HeaderNavigationSection'

export function HeaderDropDown({ navigationItem, isActive, code }: {
  navigationItem: INavigationItem;
  isActive: boolean;
  code?: string
}) {
  if(!navigationItem.subItems) {
    return null
  }

  return (<div className={cx(styles.dropDown, {
    [styles.activeMenu]: isActive,
  })}
  >
    <ul>
      {
        navigationItem.subItems && navigationItem.subItems.map(item => (
          <li key={item.url} className={styles.dropDownListItem}>
            <a href={item.url}
              className={styles.dropDownLink}
              data-navigation-elements='data-navigation-elements'
              data-region-identifier={getRegionIdentifier(code)}
            >{item.title}</a>
          </li>
        ))
      }
    </ul>
  </div>)
}
