import { showEnquire } from '@/actions/main-actions'
import { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import cx from 'classnames'

export default function IndividualBooking({ className }: { className?: string }) {
  const dispatch = useDispatch()
  const openPopup = () => {
    dispatch(showEnquire(true))
  }
  return (
    <Fragment>
      <span
        onClick={openPopup}
        className={cx(className, 'email-us-link')}
        data-fake-url='mail-us'
        data-navigation-elements='data-navigation-elements'
        data-region-identifier='menu-travelmain-contactus'
      >
        <FormattedMessage id='email.us' defaultMessage='Email us' />
      </span>
    </Fragment>
  )
}
