import styles from './LoadingIndicator.module.scss'
import cx from 'classnames'

export default function LoadingIndicator({ isMobile }) {
  return (
    <div className={cx(styles.loading, { [styles.mobile]: isMobile })}>
      <span className={styles.loadingDot}></span>
      <span className={styles.loadingDot}></span>
      <span className={styles.loadingDot}></span>
    </div>
  )
}
